import {
  Header,
  Video,
  Section2,
  TelephonePng,
  TelephoneSvg,
} from "../../images/SingleCase/Dynamo"

const dataForDynamo = {
  type: "project",
  smart: true,
  classComponent: "Dynamo",
  nextLink: "cases/ilich-realty/",
  prevLink: "cases/videoconftool/",
  data: {
    title: "Football Club Fan Application",
    subtitle:
      "Social mobile application designed & created to unite football club’s fans.",
    overview: {
      title: "Project overview",

      about: {
        title: "About client",
        content: "UAE company engaged in Influencer business, 1-10 employees",
      },
      challenge: {
        title: "Business Challenge",
        content:
          "The customer wanted to create a cross-platform social mobile application for football fans within strict deadlines because of an upcoming local football championship. Due to the strict deadlines, it was necessary to evaluate the most viable version of the product which could be released in time.",
      },
      specific: [
        {
          type: "Platform",
          content: ["iOS, Android"],
          icon: "fa-th-large",
        },
        {
          type: "Technology",
          content: ["React Native, Ext.js, Node.js, Express.js, AWS, MariaDB"],
          icon: "fa-cog",
        },
        {
          type: "Team",
          icon: "fa-users",
          content: [
            {
              type: "Project Manager",
              count: 1,
            },
            {
              type: "Developers",
              count: 5,
            },
            {
              type: "Designer",
              count: 1,
            },
            {
              type: "QA Engineers",
              count: 2,
            },
          ],
        },
        {
          type: "Duration",
          icon: "fa-calendar",
          content: [
            {
              type: "2 months",
              count: "",
            },
          ],
        },
        {
          type: "Client",
          content: ["Startup"],
          icon: "fa-user",
        },
      ],
    },
    solution: {
      title: "Solution",
      textFirst: [
        "Because of the strict deadlines we made a proposition to the customer to develop an MVP version of the mobile application first. To evaluate the features of the most viable product we made a deep analysis and market research of the football-related mobile social applications niche. When the scope of work and the functionality was approved by customer, we designed the application’s workflow based on the latest UI/UX guidelines.",
        "By following the agile development methodology, our project manager established a flexible development and quality assurance workflow. Due to the cross-platform development requirements of the app we decided to use React Native as the main technology for the front-end part of the project, and the Node.js for the back-end. To provide the ability to manage the content within the application, our team implemented an administration panel using Ext.js.",
      ],
    },

    delivered: {
      title: "Value delivered",
      list: [
        "By the end of the project, we were able to deliver a fully functional MVP, which was released right on time to both Android and iOS users. We were able to achieve this thanks to our agile approach in development and our expertise in React Native as our main technology for cross-platform mobile development. ",
        "By the end of the project, we delivered a fully functional MVP, which was released right on time for Android and iOS platforms both. This result was achieved by using an agile development methodology approach and our expertise in React Native as our main technology for cross-platform mobile application development.",
      ],
    },

    images: {
      bgTop: Header,
      secSecond: Section2,
      telephone: TelephonePng,
      telephoneBack: TelephoneSvg,
      video: Video,
    },
  },
  imageSrc: [],
  relatedProjects: [
    {
      link: "/cases/service-doc",
      id: "service-doc",
      title: "Service Doc",
    },
    {
      link: "/cases/crm-sap-integration",
      id: "crmSap",
      title: "CRM/SAP Integration",
    },
    {
      link: "/cases/gud-job",
      id: "gud-job",
      title: "Gud Job",
    },
    {
      link: "/cases/ilich-realty",
      id: "illich3",
      title: "Ilich Realty",
    },
  ],
  link: "/cases/fcteam",
}

export default dataForDynamo
