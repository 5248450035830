import React from "react"

import {
  FootballBlack,
  FootballWhite,
  VectorL,
  VectorR,
} from "../../../images/SingleCase/Dynamo"

import OverviewComponent from "../OverviewComponent"

export default class Dynamo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      startPosition: 0,
      darkPictureWidth: this.darkPictureWidth,
      pullingButtonPosition: this.pullingButtonPosition,
      windowWidth: 0,
    }
  }

  componentDidMount() {
    /* Here we define picture size for different screens */

    /* this.state.darkPictureWidth - width of 'FootballBlack', that we change by dragging
   this.state.pullingButtonPosition - position of the button that we are dragging by
   this.darkPictureWidth - additional 'FootballBlack' width, that we need for remember width before start dragging
   this.pullingButtonPosition - additional pullingButton position, that we need for remember position before start dragging
   this.whitePictureWidth - width of 'FootballWhite', that is static
   this.picturesHeight - height of both pictures
* */

    /* Define device where application running */

    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.device = "mobile"
    } else {
      this.device = "web"
    }

    /* Desktop and mobile have different events for Up and Move,
    so we define them here
    */

    this.moveEvent = this.device === "web" ? "mousemove" : "touchmove"
    this.upEvent = this.device === "web" ? "mouseup" : "touchend"
    const windowWidth = window.innerWidth
    if (windowWidth >= 1200) {
      this.darkPictureWidth = 1000
      this.darkPictureWidth /= 2
      this.pullingButtonPosition = this.darkPictureWidth - 27
      this.whitePictureWidth = 1000
    } else if (windowWidth <= 780) {
      this.darkPictureWidth = windowWidth - 50
      this.darkPictureWidth /= 2
      this.pullingButtonPosition = this.darkPictureWidth - 27
      this.whitePictureWidth = windowWidth - 50
    } else {
      const offset = 200
      this.darkPictureWidth = windowWidth - 200
      this.darkPictureWidth /= 2
      this.pullingButtonPosition = this.darkPictureWidth - 27
      this.whitePictureWidth = windowWidth - 200
    }

    this.setState({
      darkPictureWidth: this.darkPictureWidth,
      pullingButtonPosition: this.pullingButtonPosition,
    })
  }

  mouseDownHandler = event => {
    let touchPositionByX // Position of the click/touch
    /* Event object has different shape for web and mobile, so we define it here */
    if (this.device === "web") {
      event.preventDefault()
      touchPositionByX = event.clientX
    } else {
      touchPositionByX = event.touches[0].clientX
    }
    window.addEventListener(this.moveEvent, this.mouseMove)
    window.addEventListener(this.upEvent, this.mouseUp)
    this.setState({ startPosition: touchPositionByX })
  }

  mouseMove = event => {
    let newTouchPositionByX //Position of the cursor/finger after move

    /* Event object has different shape for web and mobile, so we define it here */
    if (this.device === "web") {
      newTouchPositionByX = event.clientX
    } else {
      newTouchPositionByX = event.touches[0].clientX
    }

    this.setState(prevState => {
      /* Define new darkPictureWidth and new pullingButton position */
      const newDarkPictureWidth =
        this.darkPictureWidth + (newTouchPositionByX - prevState.startPosition)
      const newPullingButtonPosition =
        this.pullingButtonPosition -
        (newTouchPositionByX - prevState.startPosition)

      /* Checking borders of the picture to prevent moving pullingButton beyond */
      if (
        newDarkPictureWidth >= 0 &&
        newDarkPictureWidth <= this.whitePictureWidth
      ) {
        return {
          darkPictureWidth: newDarkPictureWidth,
          pullingButtonPosition: newPullingButtonPosition,
        }
      }

      return {}
    })
  }

  mouseUp = () => {
    window.removeEventListener(this.moveEvent, this.mouseMove)
    window.removeEventListener(this.upEvent, this.mouseUp)
    this.darkPictureWidth = this.state.darkPictureWidth
    this.pullingButtonPosition = this.state.pullingButtonPosition
  }

  render() {
    const { data } = this.props
    let i = 0
    return [
      <section className="white-bg img " key={i++}>
        <div
          className="bgBlock bgImage Dynamo"
          style={{ backgroundImage: `url(${data.images.bgTop})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12" style={{ height: "100%" }}>
                <div className="dynamo-header-flex">
                  <div className="dynamo-header-flex-title">
                    <h1>{data.title}</h1>
                    <span>{data.subtitle}</span>
                  </div>

                  <div className="dynamo-header-flex-phone">
                    <video autoPlay loop muted playsInline>
                      <source
                        src={data.images.video}
                        alt={"video"}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>,

      <OverviewComponent {...this.props.data} key={i++} />,

      <section className="team-widget">
        <div className="team-widget-item"
             style={{
               width: `${this.whitePictureWidth}px`,
               backgroundImage: `url(${FootballWhite})`,
               backgroundSize: "contain",
             }}
        >
          <div className="team-widget-dark-side"
               style={{
                 width: `${this.state.darkPictureWidth}px`,
               }}
          >
            <img
              src={FootballBlack}
              alt='App dark theme'
              style={{
                width: `${this.whitePictureWidth}px`,
              }}
            />
          </div>
          <div
            className="button-push"
            style={{
              right: `${this.state.pullingButtonPosition}px`,
            }}
            onMouseDown={this.mouseDownHandler}
            onTouchStart={this.mouseDownHandler}
          >
            <img src={VectorL}/>
            <img src={VectorR}/>
          </div>
        </div>
      </section>,

      <section className="white-bg solution" key={i++}>
        <div className="container">
          <div className="row">
            <div className="col-md-11">
              <h2 className="noMarginTop">{data.solution.title}</h2>
              {data.solution.textFirst.map((item, i) => (
                <p className="mb-4" key={i}>{item} </p>
              ))}
            </div>
          </div>
        </div>
      </section>,

      <section className="white-bg solution last" key={i++}>
        <div className="container">
          <div className="row">
            <div className="col-md-11">
              <h2 className="Dynamo">{data.delivered.title}</h2>
              <ul>
                {data.delivered.list.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
              <p>{data.delivered.text}</p>
            </div>
          </div>
        </div>
      </section>,
    ]
  }
}
